import React from "react";

function Education() {
  return (
    <div id="education" className="component-right">
      <h4>Educational Background</h4>
      <p>
        I've had the privilege to study at several prestigious institutions. I
        earned my degree in Information Science Engineering (Ingeniería en Negocios
        y Tecnologías de la Información) from Tecnológico de Monterrey.
        Additionally, I spent a year at Fontys University of Applied Sciences in
        the Netherlands, focusing on the B.E. in ICT & Software program. During
        my time in Canada, I completed a diploma in French Literature and
        Business at Vanier College in Montreal. Currently, doing a diploma program in Applied Stock Market
        Finance. 
      </p>
    </div>
  );
}

export default Education;
