import React, { useState } from 'react';
import LinkedInLogo from "../media/LinkedInLogo.png"; 
import GmailLogo from "../media/mail.png"; 

const ContactTab = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleTab = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className={`contact-tab ${isOpen ? 'open' : ''}`} onClick={toggleTab}>
        <div className="arrow">{isOpen ? '←' : '→'}</div>
        {!isOpen && <span></span>} {/* Indicador para abrir la pestaña */}
        {isOpen && (
          <>
            <a href="mailto:hegaluna7@gmail.com">
              <img src={GmailLogo} alt="Gmail" />
            </a>
            <a href="https://www.linkedin.com/in/hectorglp99/" target="_blank" rel="noreferrer">
              <img src={LinkedInLogo} alt="LinkedIn" />
            </a>
          </>
        )}
      </div>
    );
  };
  
  
export default ContactTab;
