import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const BarChart = ({ selectedToolIndex, toolsExperience, barColors }) => {
  const [activeIndex, setActiveIndex] = useState(null); // Estado para manejar la barra activa

  const options = {
    chart: {
      type: "bar",
      height: 600 // Ajustar la altura según sea necesario
    },
    legend: {
      enabled: false
    },
    title: {
      text: "Experience with tools"
    },
    xAxis: {
      categories: toolsExperience.map((tool) => tool.name)
    },
    yAxis: {
      min: 0,
      title: {
        text: "Years of experience"
      }
    },
    series: [
      {
        name: "Years of Experience",
        data: toolsExperience.map((tool, index) => ({
          y: tool.years + tool.months / 12,
          color:
            index === selectedToolIndex
              ? "rgba(255, 0, 0, 0.5)"
              : barColors[index] // Resalta la barra si es la seleccionada
        })),
        events: {
          click: function (event) {
            const newActiveIndex = event.point.index;
            // Si se hace clic en la misma barra, la desactivamos
            if (activeIndex === newActiveIndex) {
              setActiveIndex(null);
              event.point.update({ color: barColors[newActiveIndex] });
            } // Actualiza las opciones para reflejar la selección
            if (selectedToolIndex !== null) {
              options.series[0].data[selectedToolIndex] = {
                y:
                  toolsExperience[selectedToolIndex].years +
                  toolsExperience[selectedToolIndex].months / 12,
                color: "rgba(34, 40, 49, 0.5)" // o cualquier otro color de resaltado
              };
            } else {
              // Si no, actualizamos la barra previamente activa y activamos la nueva
              if (activeIndex !== null) {
                this.data[activeIndex].update({
                  color: barColors[activeIndex]
                });
              }
              setActiveIndex(newActiveIndex);
              event.point.update({ color: "rgba(34, 40, 49, 0.5)" });
            }
          }
        }
      }
    ],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false // Deshabilitar las etiquetas de datos
        },
        shadow: true // Agregar sombra para un efecto visual
      }
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BarChart;
