import React, { useEffect, useState, useRef } from "react";

const IntroSection = () => {
  const [description, setDescription] = useState(
    "An engineer striving for a better tomorrow."
  );
  const [showTyping, setShowTyping] = useState(false);
  const [showAnimations, setShowAnimations] = useState({
    mainText: false,
    nameText: false,
    subText: false,
    descriptionText: false
  });
  const descriptionRef = useRef(null); // Nuevo useRef para el elemento de descripción

  useEffect(() => {
    const timers = [
      setTimeout(
        () => setShowAnimations((prev) => ({ ...prev, mainText: true })),
        0
      ),
      setTimeout(
        () => setShowAnimations((prev) => ({ ...prev, nameText: true })),
        1000
      ),
      setTimeout(
        () => setShowAnimations((prev) => ({ ...prev, subText: true })),
        2000
      ),
      setTimeout(() => {
        setShowAnimations((prev) => ({ ...prev, descriptionText: true }));
      }, 6000)
    ];
    timers.push(setTimeout(() => setShowTyping(true), 6000));
    return () => timers.forEach((timer) => clearTimeout(timer));
  }, []);

  useEffect(() => {
    if (showTyping) {
      const element = descriptionRef.current;
      // Forzar un reflujo del navegador
      element.style.display = "none";
      void element.offsetHeight; // leer offsetHeight
      element.style.display = ""; // revertir a su estado original

      const averageCharWidth = 7.3;
      const calculatedTextWidth = description.length * averageCharWidth;
      element.style.animation = `typing 5s steps(${description.length}) forwards, blink 1s step-end infinite`;
      element.style.width = `${calculatedTextWidth}px`;
    }
  }, [showTyping, description]); // Nuevo useEffect

  useEffect(() => {
    setDescription("Just an engineer striving to blueprint a better tomorrow.");
  }, []);

  return (
    <div className="intro-section">
      <h1
        className={`main-text ${showAnimations.mainText ? "fadeIn" : "hidden"}`}
      >
        Hi, Pleased to meet you!
      </h1>
      <h2
        className={`name-text ${
          showAnimations.nameText ? "slideFromRight" : "hidden"
        }`}
      >
        I am Héctor Eduardo
      </h2>
      <h3
        className={`sub-text ${
          showAnimations.subText ? "slideFromRight" : "hidden"
        }`}
      >
        García-Luna Puente
      </h3>
      <p
        ref={descriptionRef} // Usar el ref aquí
        className={`description-text ${
          showAnimations.descriptionText ? "cursor-blink" : "hidden"
        } ${
          showTyping ? "typing" : "transparent" // aquí es donde se añade la nueva clase
        }`}
      >
        {description}
      </p>
    </div>
  );
};

export default IntroSection;
