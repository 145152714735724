import React from "react";

function calculateAge() {
  const birthDate = new Date(1999, 3, 29); // Los meses en JavaScript empiezan desde 0
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

function AboutMe() {
  return (
    <div id="aboutMe" className="component-right">
      <h4>Who Am I?</h4>
      <p>
        At the age of {calculateAge()},  I am drawn for technology,  
        stock finance, and data analytics guides my professional aspirations. I am intrigued by how emerging technologies such as AI, 
        machine learning, and blockchain can innovate business processes. I seek opportunities that promote continuous learning, growth, and impactful contributions.
        I enjoy facing new challenges and have a knack for adapting to diverse technological and multicultural environments. My time in the 
        Netherlands and Canada has broadened my worldview, enhancing my role as a Product Owner.
      </p>
    </div>
  );
}

export default AboutMe;
