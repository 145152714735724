// PieChart.js
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC3D from "highcharts/highcharts-3d";

HC3D(Highcharts);

const PieChart = ({
  programmingLanguages,
  selectedLanguageIndex,
  onLanguageSelect
}) => {
  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0
      }
    },
    title: {
      text: "Allocation of Coding Time by Language and Framework"
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        }, // Aquí faltaba la coma
        data: programmingLanguages.map((lang, index) => ({
          name: lang.name,
          y: lang.percentage,
          color: lang.color,
          sliced: index === selectedLanguageIndex,
          selected: index === selectedLanguageIndex
        })),
        point: {
          events: {
            click: function () {
              onLanguageSelect(this.name);
            }
          }
        }
      }
    },
    series: [
      {
        data: programmingLanguages.map((lang, index) => ({
          name: lang.name,
          y: lang.percentage,
          color: lang.color,
          sliced: index === selectedLanguageIndex,
          selected: index === selectedLanguageIndex
        }))
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
