import React from "react";

function Contact() {
  const currentYear = new Date().getFullYear(); // Obtiene el año actual

  return (
    <footer>
      <p>© {currentYear} Héctor García-Luna. All rights reserved.</p>
    </footer>
  );
}

export default Contact;
