import React, { useState, useEffect } from "react";
import MonterreyLogo from '../media/RayadosLogo.jpeg';
import PSVLogo from '../media/PSVLogo.png';
import BlueJaysLogo from '../media/BlueJaysLogo.jpeg';
import RavensLogo from '../media/RavensLogo.jpeg';
import AlfaRomeoLogo from '../media/AlfaRomeoLogo.png';
// Importar imágenes para películas
import TreasurePlanet from '../media/TreasurePlanetLogo.jpeg';
import ScottPilgrim from '../media/ScottPilgrim.jpeg';
import PearlHarbor from '../media/PearlHarborLogo.jpeg';
import MidnightInParis from '../media/MidnightParisLogo.jpg';
import GoalII from '../media/Goal-II.jpg';
import Oppenheimer from '../media/OppenheimerLogo.jpeg';
import Interestelar from '../media/Interestelar.jpeg';
import WalterMitty from '../media/WalterMittyLogo.jpeg';

// Importar imágenes para libros
import LookingForAlaska from '../media/LookingForAlaska.jpeg';
import LeadersEatLast from '../media/LeadersEatLast.jpg';
import MysteriousStranger from '../media/TheMysteriousStranger.jpg';
import Sapiens from '../media/Sapiens.jpg';

// Importar imágenes para comidas
import Sushi from '../media/Japan.jpeg';
import ChickenWings from '../media/ChickenWings.jpeg';
import TomatoSoup from '../media/TomatoRoastedSoup.jpeg';

//Importar imágenes para Música
import Me from "../media/Yo.jpeg"
import Ed from "../media/EdSheeran.jpeg"
import Mario from "../media/MarioDomm.png"
import Ron from "../media/RonnieVenucci.jpeg"
import Chris from "../media/ChrisWolstenholme.jpeg"

import MotherEarthDayGif from "../media/mother-earth-day.gif";
import QuitarseGif from "../media/quitarse.gif";
import MapaGif from "../media/mapa.gif";
import VideoGif from "../media/video.gif";
import CocheGif from "../media/coche.gif";


function FunFacts() {


  const [activeIndex, setActiveIndex] = useState(0);
  const [activeMovieIndex, setActiveMovieIndex] = useState(0);
  const [activeBookIndex, setActiveBookIndex] = useState(0);
  const [activeFoodIndex, setActiveFoodIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(Me);

  const handleMouseMove = (e) => {
    const { clientX } = e;
    const screenWidth = window.innerWidth;
    const newIndex = Math.floor((clientX / screenWidth) * teams.length);
    setActiveIndex(newIndex);
  };

  const handleMovieMouseOver = (index) => {
    setActiveMovieIndex(index);
  };

  const handleBookMouseOver = (index) => {
    setActiveBookIndex(index);
  };

  const handleFoodMouseOver = (index) => {
    setActiveFoodIndex(index);
  };

  const teams = [
    { name: "C.F Monterrey", sport: "Football", image: MonterreyLogo },
    { name: "PSV Eindhoven", sport: "Football", image: PSVLogo },
    { name: "Blue Jays", sport: "Baseball", image: BlueJaysLogo },
    { name: "Ravens", sport: "American Football", image: RavensLogo },
    { name: "Red Bull Racing", sport: "Formula 1", image: AlfaRomeoLogo }
  ];
  
  // Películas
const movies = [
  { title: "Treasure Planet", image: TreasurePlanet },
  { title: "Scott Pilgrim vs the World", image: ScottPilgrim },
  { title: "Pearl Harbor", image: PearlHarbor },
  { title: "Midnight In Paris", image: MidnightInParis },
  { title: "Goal II", image: GoalII },
  { title: "Oppenheimer", image: Oppenheimer },
  { title: "Interestelar", image: Interestelar },
  { title: "The Secret Life of Walter Mitty", image: WalterMitty },
];

// Libros
const books = [
  { title: "Looking for Alaska", image: LookingForAlaska },
  { title: "Leaders Eat Last", image: LeadersEatLast },
  { title: "The Mysterious Stranger", image: MysteriousStranger },
  { title: "Sapiens: A Brief History of Humankind", image: Sapiens },
];

// Comidas
const foods = [
  { name: "Japanese Cuisine", image: Sushi },
  { name: "Chicken Wings", image: ChickenWings },
  { name: "Roasted Tomato Soup", image: TomatoSoup },
];


const handleInstrumentClick = (instrument) => {
    switch (instrument) {
        case "guitar":
            setCurrentImage(Ed); 
            break;
        case "piano":
            setCurrentImage(Mario);
            break;
        case "drums":
            setCurrentImage(Ron);
            break;
        case "bass":
            setCurrentImage(Chris);
            break;
        default:
            setCurrentImage(Me);
    }
};

  
  
  return (
    <div id="funFacts" onMouseMove={handleMouseMove}>
      <h2>Personal Interests & Hobbies</h2>
      <p>
       My personal preferences and pursuits outside the professional
        sphere:
      </p>
      <h4>So-Called Intelectual Interests:</h4>
      <h5 id="secTitle">
        Facinated about linguistics, history, geography, video making, the
        automotive industry, and aeronautical and aerospace sciences.
      </h5>
      <div id="Other">
  <ul className="interest-list">
    <li className="left-item">
      <img src={MotherEarthDayGif} alt="Languages" className="interest-gif" />
      Languages in Progress: Italian, Portuguese, Russian, Korean.
    </li>
    <li className="right-item">
      Occasional Pilot Training: From time to time, as they are expensive and require significant time.
      <img src={QuitarseGif} alt="Pilot Training" className="interest-gif" />
    </li>
    <li className="left-item">
      <img src={MapaGif} alt="Maps" className="interest-gif" />
      Map Enthusiast: A big fan of maps and fascinated by how they shape our world.
    </li>
    <li className="right-item">
      Video Creation: I love recording and editing videos, even making travel videos that I upload to YouTube.
      <img src={VideoGif} alt="Video Creation" className="interest-gif" />
    </li>
    <li className="right-item">
      Growing up with Cars: Since childhood, my dad's work in the automotive industry has given me extensive knowledge about cars, from their mechanics to the entire supply chain needed to assemble and sell them.
      <img src={CocheGif} alt="Cars" className="interest-gif" />
    </li>
  </ul>
</div>

      <div>
        <h4>Sports Enthusiast</h4>
        <p>
          Big Fan of:
        </p>
        <div className="team-carousel">
        {teams.map((team, index) => (
          <div key={team.name} className={`team-item ${index === activeIndex ? "active" : ""}`}>
            <div className="team-sport">{team.sport}</div>
            <img src={team.image} alt={team.name} />
            <div className="team-name">{team.name}</div>
          </div>
        ))}
      </div>
      </div>

      <div>
        <h4>Favorite Movies</h4>
       
        <div className="movie-carousel">
  {movies.map((movie, index) => (
    <div key={movie.title} className={`carousel-item ${index === activeMovieIndex ? "active" : ""}`} onMouseOver={() => handleMovieMouseOver(index)}>
      <img src={movie.image} alt={movie.title} />
      <p>{movie.title}</p>
    </div>
  ))}
</div>
      </div>

      <div>
        <h4>Music Abilities</h4>
        <h5>
          Click on the instrument and see the artist who inpired me to play that Instrument.
        </h5>
        <div className="music-section">
  <div className="artist-name">
    <p>{
      // Aquí determinas qué nombre mostrar basado en currentImage
      currentImage === Ed ? "Ed Sheeran" :
      currentImage === Ron ? "Ronnie Vanucci Jr." :
      currentImage === Chris ? "Chris Wolstenholme" :
      currentImage === Mario ? "Mario Domm" : "Me"
    }</p>
  </div>
  <img src={currentImage} alt="Musician" className="musician-image" />
  <ul className="instrument-list">
    <li 
        onClick={() => handleInstrumentClick("guitar")}
        className={currentImage === Ed}
    >
        Guitar
    </li>
    <li 
        onClick={() => handleInstrumentClick("drums")}
        className={currentImage === Ron ? "active" : ""}
    >
        Drums
    </li>
    <li 
        onClick={() => handleInstrumentClick("bass")}
        className={currentImage === Chris ? "active" : ""}
    >
        Bass
    </li>
    <li 
        onClick={() => handleInstrumentClick("piano")}
        className={currentImage === Mario ? "active" : ""}
    >
        Piano
    </li>
  </ul>
</div>
</div>


      <div>
        <h4>Books that I like</h4>
        <div>
       
        <div className="book-carousel">
  {books.map((book, index) => (
    <div key={book.title} className={`carousel-item ${index === activeBookIndex ? "active" : ""}`} onMouseOver={() => handleBookMouseOver(index)}>
      <img src={book.image} alt={book.title} />
      <p>{book.title}</p>
    </div>
  ))}
</div>
      </div>

      <div>
        <h4>Dishes I like</h4>
        <div className="food-carousel">
  {foods.map((food, index) => (
    <div key={food.name} className={`carousel-item ${index === activeFoodIndex ? "active" : ""}`} onMouseOver={() => handleFoodMouseOver(index)}>
      <img src={food.image} alt={food.name} />
      <p>{food.name}</p>
    </div>
  ))}
</div>
      </div>
    </div>
    </div>
  );
}


export default FunFacts;
