import React from "react";
import folderIcon from '../media/folder.png';

const Projects = () => {
    return (
        <div className="projects-container">
            <h1>Personal Projects</h1>
            <p>These are projects that I have done for practicing, exploring and acquiring new skills. Sadly, I can't show you most of the projects I've done for the companies that had employ me in the past. Overall pyhton nevertheless I'll soon upload some of them but only personal projects</p>
            <ul className="project-list">
                
            <li className="project-item">
                    <img src={folderIcon} alt="Folder" className="folder-icon" />
                    <a href="../ProjectsWeb/Blog-With-Database-final.zip" download>Blog</a>
                </li>

                <li className="project-item">
                <img src={folderIcon} alt="Folder" className="folder-icon" />
                  <a href="../ProjectsWeb/dbank.zip" download>DBank</a>
                  </li>
                <li className="project-item">
                <img src={folderIcon} alt="Folder" className="folder-icon" />
                <a href="../ProjectsWeb/DiceGame.zip" download>Dice Game</a>
                </li>
                <li className="project-item">
                <img src={folderIcon} alt="Folder" className="folder-icon" />
                  <a href="../ProjectsWeb/Drum Kit Project.zip" download>Drum Kit Page</a>
                  </li>
                <li className="project-item">
                  <img src={folderIcon} alt="Folder" className="folder-icon" />
                  <a href="../ProjectsWeb/Secrets Project.zip" download>Secret Keeper</a>
                  </li>
                <li className="project-item">
                  <img src={folderIcon} alt="Folder" className="folder-icon" />
                <a href="../ProjectsWeb/Simon Game.zip" download>Simon Game</a>
                </li>
                <li className="project-item">
                  <img src={folderIcon} alt="Folder" className="folder-icon" />
                <a href="../ProjectsWeb/TinDog Project.zip" download>Tinder for dogs</a>
                </li>
                <li className="project-item">
                  <img src={folderIcon} alt="Folder" className="folder-icon" />
                <a href="../ProjectsWeb/website-project.zip" download>Dutch Company Website</a>
                </li>
                <li className="project-item">
                  <img src={folderIcon} alt="Folder" className="folder-icon" />
                <a href="../ProjectsWeb/token.zip" download>My Crypto Currency </a>
                </li>
                <li className="project-item">
                  <img src={folderIcon} alt="Folder" className="folder-icon" />
                <a href="../ProjectsWeb/TO-DO Lamosa.zip" download>A To-Do List for my co-workers</a>
                </li>
    
                {/* Más proyectos */}
            </ul>
        </div>
    );
};

export default Projects;

            
            
         