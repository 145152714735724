import React from 'react';
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Egg from "../media/Eggechute.gif";
import Orange from "../media/orange.png";
import Blue from "../media/blue.jpg";

const SoftSkills = () => {
    return (
        <div className="softSkills-container">
        <div>
            <Parallax pages={3}>
                <ParallaxLayer
                    offset={0}
                    speed={0.5}
                    factor={3}  // Ocupa 4 páginas
                    style={{
                        backgroundImage: `url(${Blue})`,
                        backgroundSize: 'cover',
                    }}
                />

                {/* Capa del paracaidista */}
                <ParallaxLayer
                    sticky={{start: 0.1, end: 1.9}}
                    style={{ width: '20%' }}
                >
                    <img  className='eggParachute' src={Egg} alt="Egg Parachute" style={{ display: 'block', margin: '1vh' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.4} speed={0.4}>
                <div className='softSkill-left'>
                <h2>Effective Communication</h2>
                <p>I focus on being clear, concise, and effective in my communication. I continuously practice verbal, written, and digital communication to keep getting better.</p>
            </div>

                </ParallaxLayer>
                <ParallaxLayer offset={0.6} speed={0.4}>
                <div className='softSkill-right'>
                <h2>Teamwork</h2>
                <p>I actively collaborate with my colleagues, resolving conflicts and building positive relationships. I value diverse opinions and working styles to enrich our teamwork.</p>
            </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0.95} speed={0.4}>
                <div className='softSkill-left'>
                <h2>Problem-Solving</h2>
                <p>I excel in identifying problems and coming up with creative solutions. Critical thinking and objective evaluation of information are key in my decision-making process.</p>
            </div>
                </ParallaxLayer>
                <ParallaxLayer offset={1} speed={0.4}>
                <div className='softSkill-right'>
                <h2>Adaptability</h2>
                <p>I stay flexible and open to learning new things, quickly adapting to different situations.</p>
            </div>
                </ParallaxLayer>
                <ParallaxLayer offset={1.2} speed={0.4}>
                <div className='softSkill-left'>
                <h2>Leadership</h2>
                <p>I motivate and inspire my team, guiding us towards common goals. My leadership style is inclusive and accessible.</p>
            </div>
                </ParallaxLayer>
                <ParallaxLayer offset={1.5} speed={0.4}>
                      <div className='softSkill-right'>
                <h2>Self-Motivation</h2>
                <p>I set clear goals and work diligently to achieve them. My approach to professional and personal success is self-driven.</p>
            </div>

                </ParallaxLayer>
                <ParallaxLayer offset={1.7} speed={0.4}>
                <div className='softSkill-left'>
                <h2>Resilience</h2>
                <p>I face challenges and setbacks with a positive attitude, learning from each experience. I stay calm and focused under stress or change, bouncing back quickly.</p>
            </div>

                </ParallaxLayer>
               

                


                {/* Capa de aterrizaje */}
                <ParallaxLayer
                    offset={2}  // Comienza después de la imagen blue
                    speed={0.5}
                    factor={1}  // Ocupa 2 páginas
                    style={{
                        backgroundImage: `url(${Orange})`,
                        backgroundSize: 'cover',
                    }}
                />

<ParallaxLayer offset={2.5} speed={0.4}>
                <div className='softSkill-center'>
                <h2>Developing These Soft Skills</h2>
                <p>Active Observation: I pay attention to colleagues and leaders who demonstrate exemplary skills, learning from their behavior and techniques.</p>
                <p>Constant Feedback: I seek feedback from peers and mentors to identify areas for improvement and strengths.</p>
                <p>Continuous Practice: I look for opportunities to apply these skills in real-life situations, improving through experience.</p>
                <p>Development Opportunities: I participate in training, courses, and volunteer activities that enrich my skills and knowledge.</p>
            </div>
                </ParallaxLayer>
            </Parallax>
        </div>
        </div>
    );
}

export default SoftSkills;

