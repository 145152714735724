import React, { useState, useEffect, useRef } from "react";
import RadarChart from "./RadarChart";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
//Flags 
import MexicoFlag from "../media/Spanish.jpeg"; 
import USFlag from "../media/English.avif";
import FrenchFlag from "../media/French.png";
import NetherlandsFlag from "../media/Dutch.png";
import GermanFlag from "../media/German.avif";

//Crazy stuff starts
const HardSkills = () => {
  const multilingualRef = useRef(null);
  const [selectedToolIndex, setSelectedToolIndex] = useState(null);
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(null);
  const [percentages, setPercentages] = useState({
    Spanish: 0,
    English: 0,
    French: 0,
    Dutch: 0,
    German: 0,
  });

  // Definición de toolsExperience justo antes del componente HardSkills
  const toolsExperience = [
    { name: "PowerBI", years: 2, months: 0 },
    { name: "Tableau", years: 1, months: 5 },
    { name: "MiniTab", years: 3, months: 0 },
    { name: "Dynamics 365 Finance & Operations", years: 1, months: 3},
    { name: "Dynamics 365 Customer Engagement", years: 1, months: 3 },
    { name: "Cornerstone", years: 0, months: 9 },
    { name: "Jira", years: 0, months: 6 },
    { name: "MongoDB", years: 0, months: 3 },
    { name: "Excel", years: 4, months: 0 },
  ];
  const programmingLanguages = [
    { name: "HTML5 & CSS", percentage: 10, color: "#FD8600" },
    { name: "JavaScript", percentage: 10, color: "#EAFD00" },
    { name: "React.js", percentage: 24, color: "#1666FA" },
    { name: "SQL", percentage: 10, color: "#9B07B8" },
    { name: "Node.js", percentage: 4, color: "#06E6B0" },
    { name: "SwiftUI", percentage: 12, color: "#F60400" },
    { name: "Python", percentage: 14, color: "#70FF0C" },
    { name: "Motoko Language", percentage: 3, color: "#1F1E2B" },
    { name: "Visual Basic For Applications", percentage: 8, color: "#185C37" },
    { name: "R", percentage: 5, color: "#666666" },
  ];

  const flags = {
    Spanish: MexicoFlag,
    English: USFlag,
    French: FrenchFlag,
    Dutch: NetherlandsFlag,
    German: GermanFlag
};
  
  const handleToolClick = (index) => {
    setSelectedToolIndex(index);
  };

  const handleLanguageClick = (index) => {
    setSelectedLanguageIndex(index);
  };


  const barColors = [
    "#F9ED69",
    "#F08A5D",
    "#B83B5E",
    "#6A2C70",
    "#61A3BA",
    "#FFFFDD",
    "#D2DE32",
    "#A2C579",
    "#A9A9A9",
    "#557C55",
  ];

  

  const data = {
    labels: programmingLanguages.map((lang) => lang.name),
    datasets: [
      {
        label: "Programming Languages",
        data: programmingLanguages.map((lang) => lang.percentage),
        backgroundColor: programmingLanguages.map((lang) => lang.color),
      },
    ],
  };

  useEffect(() => {
    const handleScroll = () => {
        if (multilingualRef.current) {
            const rect = multilingualRef.current.getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                // Asegúrate de que solo inicies la animación una vez
                if (percentages.Spanish === 0) { // Reemplaza con una condición adecuada para tu lógica
                    const interval = setInterval(() => {
                        setPercentages((prev) => ({
                            Spanish: Math.min(prev.Spanish + 2, 100),
                            English: Math.min(prev.English + 2, 98),
                            French: Math.min(prev.French + 2, 95),
                            Dutch: Math.min(prev.Dutch + 2, 60),
                            German: Math.min(prev.German + 1, 40),
                        }));
                    }, 60);
                    setTimeout(() => {
                        clearInterval(interval);
                    }, 3000);
                }
            }
        }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
}, [percentages]);



    return (
      <div id="hardSkills">
        <h2>Technical Abilities</h2>
        <p>
          Proficient with a range of contemporary data visualization, business
          intelligence, and project management tools, with a commitment to
          continuous learning and mastery:
        </p>
        <div id="technicalProficiencies" className="tech-container">
          <ul className="no-bullets">
            {toolsExperience.map((tool, index) => (
              <li
                key={tool.name}
                onClick={() => handleToolClick(index)}
                className={selectedToolIndex === index ? "active" : ""}
              >
                {tool.name}
              </li>
            ))}
          </ul>
          <BarChart
            selectedToolIndex={selectedToolIndex}
            toolsExperience={toolsExperience}
            barColors={barColors}
          />
        </div>
        <h4>Programming Languages and Frameworks</h4>
        <p>
          This pie chart illustrates the distribution of my programming
          expertise over time, with each segment representing the proportion of
          my total coding experience dedicated to a specific language—not the
          extent of my knowledge, but the time invested in each.
        </p>

        <div className="skills-layout">
          <div className="skills-list">
            <div id="programmingLanguagesList">
              <ul>
                {programmingLanguages.map((language, index) => (
                  <li
                    key={language.name}
                    className={`programming-language ${
                      index === selectedLanguageIndex ? "active" : ""
                    }`}
                    onClick={() => handleLanguageClick(index)}
                  >
                    {language.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <PieChart
            programmingLanguages={programmingLanguages}
            selectedLanguageIndex={selectedLanguageIndex}
            onLanguageSelect={handleLanguageClick} // Asegúrate de que esta función esté definida
          />
        </div>

        <div id="multilingualCapabilities" ref={multilingualRef}>
          <h4>Multilingual Capabilities</h4>
          <p>
            Able to communicate effectively in multiple languages, with varying
            degrees of proficiency:
          </p>
          {[
            { language: "Spanish", percentage: percentages.Spanish },
            { language: "English", percentage: percentages.English },
            { language: "French", percentage: percentages.French },
            { language: "Dutch", percentage: percentages.Dutch },
            { language: "German", percentage: percentages.German },
          ].map((item) => (
            <div key={item.language} className="progress-container">
              <span className="language">
                            <img src={flags[item.language]} alt={item.language + " flag"} style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px' }} />
                            {item.language}
                        </span>
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{ width: `${item.percentage}%` }}
                ></div>
              </div>
              <span className="percentage">{item.percentage}%</span>
            </div>
          ))}

          
                   
        </div>
        <div className="radar-container">
          <h2>My Goals for Skill and Knowledge Acquisition</h2>
                    <p>I am currently enrolled in a diploma program from "I.T.E.S.M" in Applied Stock Market Finance. So far this is the proficency I have over the following topics displayed as a radar chart</p>

          <RadarChart />

          <p>Completion Date of the Diploma Program: August 16, 2024</p>
          </div>
      </div>
    );
};


export default HardSkills;
