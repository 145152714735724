import React from "react";
import { useNavigate } from "react-router-dom"; // Replace useHistory with useNavigate
import logoImage from "../media/Logo.png";

function Logo() {
  const navigate = useNavigate(); // Use useNavigate here

  const handleLogoClick = () => {
    navigate("/"); // Use navigate('/') to navigate to the home route
  };

  return (
    <div className="logo-container" onClick={handleLogoClick}>
      <img src={logoImage} alt="Logo" className="logo" />
    </div>
  );
}

export default Logo;
