import React, { useEffect } from 'react';
import "../styles.css";
import * as THREE from "three";
import MoonWrap from "../media/MoonWrap.jpeg";
import RingWrap from "../media/spacewraper.jpeg";

const Dona = () => {
    useEffect(() => {
        // Inicialización
        const scene = new THREE.Scene();
 // Ajustar la cámara para un aspecto más ancho
 const aspectRatio = 1.5; // Más ancho que alto
 const camera = new THREE.PerspectiveCamera(90, aspectRatio, 0.1, 1000);
 camera.position.setZ(30);

 const canvas = document.querySelector("#bg");
 const renderer = new THREE.WebGLRenderer({
     canvas: canvas,
     alpha: true // Fondo transparente
 });

 renderer.setPixelRatio(window.devicePixelRatio);
 const canvasWidth = 450; // Ancho más grande
 const canvasHeight = canvasWidth / aspectRatio;
 renderer.setSize(canvasWidth, canvasHeight);
       
    


        // Geometría Torus más grande
        const RingWraper = new THREE.TextureLoader().load(RingWrap);
        const Ring = new THREE.Mesh(
            new THREE.TorusGeometry(19, 1, 15, 50),// Aumentar el tamaño de la dona
            new THREE.MeshStandardMaterial({ map: RingWraper })
        );
       
        

        // Luces
        const pointLight = new THREE.PointLight(0xffffff);
        pointLight.position.set(5, 5, 5);

        const ambientLight = new THREE.AmbientLight(0xffffff);
        scene.add(pointLight, ambientLight);

        // Carga de textura y creación de malla
        const MoonWraper = new THREE.TextureLoader().load(MoonWrap);
        const hector = new THREE.Mesh(
            new THREE.SphereGeometry(12, 32, 16),
            new THREE.MeshStandardMaterial({ map: MoonWraper})
        );

        // Posicionar el cubo más cerca del centro de la dona
        hector.position.set(5, 0, 0);

        scene.add(Ring, hector);

        // Animación
       // Animación
const animate = () => {
    requestAnimationFrame(animate);
    Ring.rotation.x += 0.01;
    Ring.rotation.y += 0.005;
    Ring.rotation.z += 0.01;

    // Rotación del cubo a diferente velocidad
    hector.rotation.x += 0.005;
    hector.rotation.y += 0.01;
    hector.rotation.z += 0.005;

    renderer.render(scene, camera);
};


        animate();

        // Limpieza al desmontar
        return () => {
            scene.remove(Ring, hector);
        };
    }, []);

    return <canvas id="bg" />;
};

export default Dona;
