import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart,
  RadialLinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  RadarController
} from "chart.js";

Chart.register(
  RadialLinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  RadarController
);

const RadarChartSimple = () => {
  const data = {
    labels: ["Stock Market Analysis", "Derivative and Debt Instruments", "Investment Management", "Risk Management", "Global Markets and International Organizations"],
    datasets: [
      {
        label: "Percentage of expected dominance",
        data: [90, 85, 80, 95, 75],
        backgroundColor: "rgba(108, 46, 156, 0.4)", // Opacidad del relleno
        borderColor: "#6c2e9c", // Color del borde
        borderWidth: 1,
        pointBackgroundColor: "#6c2e9c",
        pointRadius: 4 // Esto hará que los puntos de datos no se dibujen
      }
    ]
  };

  const options = {
    scale: {
      ticks: {
        beginAtZero: true,
        max: 100,
        stepSize: 20,
        display: false, // Esto debería ocultar los números del eje radial
        showLabelBackdrop: false // Esto debería ocultar el fondo de las etiquetas
      },
      gridLines: {
        color: "#0d284f"
      },
      angleLines: {
        color: "#0d284f"
      },
      pointLabels: {
        fontColor: "#eadbc8",
        fontSize: 100, // Ajusta este valor según lo que necesites
        fontStyle: "bold" // Esto hará que el texto sea negrita
      },
      backgroundColor: "#eadbc8"
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false
  };

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <Radar data={data} options={options} />
    </div>
  );
};

export default RadarChartSimple;
