import React from "react";
import HectorPicture from "../media/MOPic.jpeg";

const ModusOperandi = () => {
  function calculateAge() {
    const birthDate = new Date(1999, 3, 29); // Los meses en JavaScript empiezan desde 0
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
  
    return age;
  }
  return (
    <div className="modus-operandi">
    <h1>M.O.</h1>
    <h2>Exploring the Horizon of Possibility</h2>
    <div className="modus-container">
      <div className="paragraphs-container">
        <p className="p1">
        At my core, I am driven by resilience and a ceaseless pursuit of knowledge. Transitioning from mechanical engineering to computer science, and finally to information science engineering, I have not only met the rigorous demands of each discipline but also excelled within them, culminating in graduating on time despite the added challenge of a year-long international study program. This journey underscores my capacity to adapt and thrive in diverse academic environments.</p>
        <p className="p2">
        My passion for global cultures ignited early in life, leading me to master multiple languages beyond the traditional educational scope. Fluent in Spanish, English, French, Dutch, and German, and currently advancing in Russian, Korean, Italian, and Portuguese, I have developed a nuanced understanding of linguistic subtleties and cultural dynamics. This skillset is not just about communication—it’s a strategic tool that enhances my analytical capabilities in global markets. </p>
      </div>
      <div className="modus-image">
        <img src={HectorPicture} alt="Hector" />
      </div>
      </div>

      <p className="p3">
        Professionally, I am enhancing my financial acumen through certification in AMIB Figure 3, akin to the CFA in Mexico, with future plans to pursue an MBA combined with an MSc in Economics. These qualifications are crucial as I forge a path as an investment strategist, where my expertise can significantly impact financial planning and client advisories.
      </p>
      <p className="p4">
      Throughout my career, I have learned the importance of mentorship and the invaluable insights that can be gained from those with more experience. This belief extends beyond formal mentorship; I hold a firm conviction that every individual has something valuable to teach, and I actively seek to learn from everyone I encounter, regardless of their role or background. This approach has not only enriched my professional life but also deepened my personal growth.
      </p>
      <p className="p5">
      My approach to leadership is informed by both personal and philosophical growth. After overcoming personal challenges, including significant weight loss to improve health and professional image, I have learned the importance of calm and stoic reflection from Marcus Aurelius's teachings. These experiences have been instrumental in shaping my leadership style, focusing on empathy, team cohesion, and proactive problem-solving.
      </p>

      <p className="p6">
      Leading a diverse team at a young age, I have proven that effective leadership is not constrained by age but defined by maturity and the ability to inspire trust and collaboration. I strive to be a leader who learns with his team, setting an example through active participation and a commitment to collective success.
      </p>

    <p className="p7">
    In summary, my professional ethos is built on a foundation of continuous personal and professional development, underscored by a commitment to innovation, empathetic leadership, and a profound understanding of the global landscape. My goal is not only to advance in my career but also to inspire those around me to achieve their best, making every project not just a task to be completed, but a step towards collective excellence.
    </p>
    </div>
  );
};

export default ModusOperandi;
