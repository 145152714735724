import React, { useState, useEffect } from "react";

function Header() {
  const [isVisible, setIsVisible] = useState(true);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header-container ${isVisible ? "visible" : "hidden"}`}>
        <nav className="header-nav">
            <a href="#aboutMe" onClick={() => scrollTo("aboutMe")}>About Me</a>
            <a href="#experience" onClick={() => scrollTo("experience")}>Experience</a>
            <a href="#education" onClick={() => scrollTo("education")}>Education</a>
        </nav>
    </header>
);
}

export default Header;