import React, { useState } from "react";
import { Link } from "react-router-dom";

function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="menu-container">
      {" "}
      {/* Nuevo contenedor */}
      <div id="burgerMenu" className="burger-container">
        {/* ...contenido del burger-container... */}
        <div
          className={`burger-button ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="burger-line"></div>
          <div className="burger-line"></div>
          <div className="burger-line"></div>
        </div>
      </div>
       <div className={`burger-menu ${isOpen ? "show" : ""}`}>
        <Link to="/" className="menu-link">Home</Link>
        <Link to="/hard-skills" className="menu-link">Hard Skills</Link>
        <Link to="/soft-skills" className="menu-link">Soft Skills</Link>
        <Link to="/financial-skills" className="menu-link">Financial Skills</Link>
        <Link to="/projects" className="menu-link">Projects</Link>
        <Link to="/fun-facts" className="menu-link">Hobbies</Link>
        <Link to="/modus-operandi" className="menu-link">Modus Operandi</Link>
      </div>
    </div>
  );
}

export default BurgerMenu;
