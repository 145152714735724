import React from "react";

function Experience() {
  return (
    <div id="experience" className="component-left">
      <h4>Work Experience</h4>
      <p>
        My professional journey has required me to work in multiple languages. I started my career at
        CEMEX, where I was involved in Change Management; in France, I operated
        entirely in French, then I was requested to work with  Mexico in Spanish, and in Lithuania in English. Afterwards,
         I moved  to Segamac in Santa Catrina, Nuevo León, Mexico which is an operating company of Mateco based in
        Belgium, starting as an IT Supervisor before being promoted to Junior
        Business Analyst. I'm currently at Grupo Lamosa in San Pedro Garza Garcia, Nuevo León, Mexico, a multinational
        organization operating in 9 countries, serving as the Product Owner for
        Cornerstone, also known as Mega.
      </p>
    </div>
  );
}

export default Experience;
