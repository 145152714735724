import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./Header";
import BurgerMenu from "./BurgerMenu";
import AboutMe from "./AboutMe";
import Experience from "./Experience";
import Education from "./Education";
import Contact from "./Contact";
import SoftSkills from "./SoftSkills";
import HardSkills from "./HardSkills";
import FinancialSkills from "./FinancialSkills";
import Projects from "./Projects";
import FunFacts from "./FunFacts";
import ModusOperandi from "./ModusOperandi";
import IntroSection from "./IntroSection";
import Logo from "./Logo";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles.css";
import Dona from "./Dona";
import ContactTab from "./ContactTab";

function HomePage() {
    return (
        <div className="main-content">
            <ContactTab />
            <div className="banner-container">
                <div className="intro-dona-container">
                    <IntroSection />
                    <Dona />
                </div>
            </div>
            <div className="component-right">
                <AboutMe />
            </div>
            <div className="component-left">
                <Experience />
            </div>
            <div className="component-right">
                <Education />
            </div>
            <Contact />
        </div>
    );
}



function MainApp() {
  const location = useLocation();

   // Verificar si la ruta actual es la página de inicio
   const isHomePage = location.pathname === "/";

    return (
        <div className="container">
            <div className="row">
                <div className="flex-container">
                    <div className={isHomePage ? "logo" : "logo-fixed"}>
                        <Logo />
                    </div>
          {isHomePage && <Header />}
          <BurgerMenu />
                </div>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/soft-skills" element={<SoftSkills />} />
                    <Route path="/hard-skills" element={<HardSkills />} />
                    <Route path="/financial-skills" element={<FinancialSkills/>} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/fun-facts" element={<FunFacts />} />
                    <Route path="/modus-operandi" element={<ModusOperandi />} />
                </Routes>
            </div>
        </div>
    );
}

function App() {
    return (
            <Router>
                <MainApp />
            </Router>
    );
}

export default App;
