import React from "react";
import UndCon from "../media/UnderConstruction.gif";


function FinancialSkills() {
    return (
        <div className="centered-fin">
            <h1>Page under Construction</h1>
            <h2>Available soon</h2>
            <img src={UndCon} alt="Under Construction sign for Finance Web page" />
        </div>
    );
}

export default FinancialSkills;

